import {Container, Loader, MaxBtn} from "../core/components";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {MiniBtn, MyModal, TextfieldInput} from "../core/input_fields";
import {EventKindMap, ExamTypeMap, QuestionTopicLabelMap, QuestionTopicMap} from "../core/enums";
import {useHistory} from "react-router";
import {FormContextWrapper} from "../core/form_context";
import VideoanleitungR from "./video";
import {UserContext} from "../user/UserContext";
import Consts from "../core/consts";
import UserHistory from "../user/userHistory";
import {QuestionEditWrapper} from "./questionEdit";
import {encodeGetParams} from "../core/helpers";

const parseFloatAt = a => (typeof a === "string" ? parseFloat((a || "0").replace(",", ".")) : a) || 0

const fieldLabels = {
    a1: "A-Note 1",
    a2: "A-Note 2",
    b1: "B-Note 1",
    b2: "B-Note 2",
    b1t: "B-Note 1 Technik",
    b1h: "B-Note 1 Haltung",
    b1d: "B-Note 1 Dynamik",
    neutral: "Neutral",
    neutral2: "Neutral 2",

}


function VideoPlayerButton({examquestion_ID, loopOnce, video}) {
    const [videos, setVideos] = useState(null)
    const [{error, loading}, setStatusVar] = useState({})

    if (videos === null) {
        return <div>
            <Status type={"error"} text={error}/>
            <MaxBtn onClick={() => maxiGet(`/exam/examquestion/${examquestion_ID}/video?` + encodeGetParams({video}), {setStatusVar}).then(setVideos)}>Video abspielen</MaxBtn> <Loader loading={loading}/>
        </div>
    }
    if (!videos?.video) {
        return <p>Video bereits abgespielt</p>
    }
    return <>
        {
            videos?.video && <VideoanleitungR content={videos.video} loopOnce={loopOnce} noControls autoplay key={videos.video} onEnded={() => {
                setVideos(old => ({...old, video: null}))
            }}/>
        }
    </>

}

export function Examstart({match}) {
    const {examstart_ID, examquestion_index: examquestion_index_raw} = match.params;
    const examquestion_index = examquestion_index_raw !== undefined ? parseInt(examquestion_index_raw) : undefined;
    const history = useHistory();

    const {user} = useContext(UserContext);

    const [timer0, setTimer0] = useState()
    const [timer, setTimer] = useState()

    const [responses, setResponses] = useState({})

    const [anyChange, setAnyChange] = useState(false);

    const [data, setData] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    const loadExamstart = () => maxiGet(`/exam/examstart/${examstart_ID}`, {setStatusVar}).then(data => {
        setData(data)
        setResponses(Object.values(data.exammap).reduce((obj, curr) => ({...obj, [curr.examquestion.ID]: curr.examquestion.response}), {}))
    })
    useMemo(loadExamstart, [examstart_ID]);
    useMemo(() => {
        setAnyChange(false)
    }, [examstart_ID, examquestion_index]);

    const {examstart, exam, examobject, exammap, others, examtopics, person, event} = data || {};
    const examquestion = exammap && examquestion_index !== undefined && exammap[examquestion_index]
    const topicarray = examquestion && examobject[examquestion.question.topic.value];
    const eq_ID = examquestion && examquestion.examquestion.ID;
    const response = responses[eq_ID] ?? []

    const writeable = () => exam.type.value !== ExamTypeMap.selfservice || (new Date().getTime() - new Date(examstart?.timeCreated).getTime()) / 1000 / 60 < 40


    const saveAndRedir = (toIndex, save = true, examstart_ID_local = examstart_ID) => {
        if (exam.type.value === ExamTypeMap.selfservice && save && !(response?.length ?? Object.entries(response).length) && writeable() && (examstart.parent_ID != null || exam.type.value === ExamTypeMap.selfservice)) {
            setStatusVar({error: "Es ist zumindest eine der Antwortmöglichkeiten richtig, bitte wähle daher eine aus."})
            return
        }
        setTimer(null)
        setTimer0(null)
        const next = () => examquestion_index !== toIndex && history.push(`/prüfungsantritt/${examstart_ID_local}/${toIndex}`);
        writeable() && save ? maxiPost(`/exam/examquestion/${examquestion.examquestion.ID}/answer`, response, {setStatusVar}).then(next) : next()
    }

    const MyFormContextWrapper = exam?.type?.value === ExamTypeMap.synchronous ? FormContextWrapper : React.Fragment

    const valueValidationErrors = (() => {
        if (!response || exam?.type.value !== ExamTypeMap.synchronous) {
            return
        }
        let e = "";
        Object.entries(response).forEach(([key, value]) => {
            const v = value?.replace(",", ".")
            if (v) {
                e += v >= 0 && v <= 10 ? "" : `Die ${fieldLabels[key]} muss zwischen 1 und 10 liegen.`
                if (key.startsWith("a")) {
                    e += v % 1 === 0 ? "" : `Die ${fieldLabels[key]} muss ganzzahlig sein.`
                } else {
                    e += v % 1 === 0 || v % 1 === 0.5 ? "" : `Die ${fieldLabels[key]} muss ganzzahlig sein oder in halben Schritten angegeben werden.`
                }
            }
        })
        return e
    })()

    return <Container name={exam?.name}>
        <Status text={error} type={"error"}/>
        <MyFormContextWrapper value={{state: responses, setState: setResponses}}>
            {
                examquestion && <>
                    {Consts.f(true, false) && <>{examquestion.question.ID}</>}
                    {
                        exam.type.value === ExamTypeMap.selfservice && <i>
                            Die Fragen beziehen sich alle auf die Basisstufe (wenn nicht, ist es dezidiert angeführt). Grundsätzlich ist immer eine Antwortmöglichkeit richtig. Es gibt aber pro Themenpool eine Frage mit einer Mehrfachauswahl – darauf wird in der Fragestellung hingewiesen.
                        </i>
                    }


                    <p style={{lineHeight: "34px"}}>
                        {Object.entries(examobject).map(([key, object]) =>
                            // todo: Herumspringen, speichern, keine Eingabe

                            // herumspringen ändern
                            <>
                                <MiniBtn style={{
                                    borderColor: key === examquestion.question.topic.value ? "#444" : "red", backgroundColor: key === examquestion.question.topic.value ? "#444" : undefined,
                                    color: key === examquestion.question.topic.value ? "white" : undefined
                                }} onClick={() => (exam.type.value === ExamTypeMap.selfservice || user?.role >= 38) && saveAndRedir(object[0], examstart.person_ID === user?.ID)}>
                                    {QuestionTopicLabelMap[key]}
                                </MiniBtn>&nbsp;
                            </>)}
                    </p>

                    <p>
                        {exam.type.value === ExamTypeMap.synchronous ? "Übung" : "Frage"} {examquestion_index - topicarray[0] + 1} von {topicarray.length} im Themenpool {examquestion.question.topic.label}

                        {
                            exam.type.value === ExamTypeMap.synchronous && examquestion.question.remark ? " – " + examquestion.question.remark + "." : "."
                        }

                        <div style={{backgroundColor: "white", border: "2px solid red", borderRadius: 4, marginTop: 5}}>
                            <div style={{backgroundColor: "red", height: 10, width: (((examquestion_index - topicarray[0] + 1) / topicarray.length) * 100) + "%"}}></div>
                        </div>

                    </p>


                    {
                        exam.type.value === ExamTypeMap.selfservice && <h3>{examquestion.question.question.trim().split("\n").map(a => [a, <br/>])}</h3>
                    }

                    {
                        exam.type.value === ExamTypeMap.selfservice && <ul style={{listStyle: "none", lineHeight: "35px", paddingLeft: 0}}>
                            {examquestion.choices.map(choice => <li>


                                <input type={"checkbox"} checked={(responses[eq_ID] || []).includes(choice.ID)}
                                       onClick={() => {
                                           if (writeable()) {
                                               setAnyChange(true)
                                               setResponses(old => ({...old, [eq_ID]: (old[eq_ID] || []).includes(choice.ID) ? old[eq_ID].filter(a => a !== choice.ID) : [...(old[eq_ID] || []), choice.ID]}))
                                           } else {
                                           }
                                       }}
                                /> {choice.text}


                            </li>)
                            }
                        </ul>
                    }


                    {
                        exam.type.value === ExamTypeMap.synchronous && <div style={{maxWidth: "100%"}}>
                            {
                                !examstart.parent_ID && <>
                                    <VideoanleitungR content={examquestion.question.video} key={examquestion.question.video} onEnded={() => {
                                        setTimer0(new Date())
                                    }}/>
                                    {
                                        timer0 && <ShowClock start={timer0}/>
                                    }
                                    {
                                        (examquestion.question.topic.value === QuestionTopicMap.sprung || examquestion.question.topic.value === QuestionTopicMap.tramp) && <>
                                            <h3>Video 2</h3>
                                            <VideoanleitungR content={examquestion.question.video2} key={examquestion.question.video2} onEnded={() => {
                                                setTimer(new Date())
                                            }}/>
                                        </>
                                    }
                                </>
                            }


                            {
                                timer && <ShowClock start={timer}/>
                            }
                            <>
                                <h3>Wertungseingabe</h3>

                                {
                                    (examquestion.question.topic.value === QuestionTopicMap.sprung || examquestion.question.topic.value === QuestionTopicMap.tramp) ?
                                        <>

                                            <h3>Sprung 1</h3>
                                            {
                                                event?.kind.value === EventKindMap.onlinepruefung && <VideoPlayerButton loopOnce examquestion_ID={examquestion.examquestion.ID} key={examquestion.examquestion.ID} video={"video"}/>
                                            }
                                            <TextfieldInput name={"A-Note 1"} tag={`${eq_ID}_a1`} labelWidth={80} width={60} ph={0}/><br/>

                                            <TextfieldInput name={"B-Note 1"} tag={`${eq_ID}_b1`} width={60} labelWidth={80} step={0.1} ph={0}/> <br/>
                                            <TextfieldInput name={"Neutral 1"} tag={`${eq_ID}_neutral`} width={60} labelWidth={80} ph={0}/>
                                            <br/>
                                            <br/>

                                            <h3>Sprung 2</h3>
                                            {
                                                event?.kind.value === EventKindMap.onlinepruefung && <VideoPlayerButton loopOnce examquestion_ID={examquestion.examquestion.ID} key={examquestion.examquestion.ID} video={"video2"}/>
                                            }
                                            <TextfieldInput name={"A-Note 2"} tag={`${eq_ID}_a2`} step={1} labelWidth={80} width={60} ph={0}/><br/>
                                            <TextfieldInput name={"B-Note 2"} tag={`${eq_ID}_b2`} width={60} labelWidth={80} step={0.1} ph={0}/><br/>
                                            <TextfieldInput name={"Neutral 2"} tag={`${eq_ID}_neutral2`} width={60} labelWidth={80} ph={0}/>
                                        </> :
                                        <>
                                            {
                                                event?.kind.value === EventKindMap.onlinepruefung && <VideoPlayerButton examquestion_ID={examquestion.examquestion.ID} key={examquestion.examquestion.ID} video={"video"}/>
                                            }
                                            <TextfieldInput name={"A-Note"} tag={`${eq_ID}_a1`} labelWidth={80} width={60} ph={0}/>
                                            <br/>
                                            <strong style={{width: 90, display: "inline-block"}}>B-Note</strong>&nbsp;
                                            <TextfieldInput name={"Technik"} tag={`${eq_ID}_b1t`} width={60} labelWidth={70} step={0.1} ph={0}/> + &nbsp;
                                            <TextfieldInput inline name={"Haltung"} tag={`${eq_ID}_b1h`} width={80} labelWidth={70} step={0.1} ph={0}/> + &nbsp;
                                            <TextfieldInput name={"Dynamik"} tag={`${eq_ID}_b1d`} width={60} labelWidth={70} step={0.1} ph={0}/> = &nbsp;
                                            {(parseFloatAt(response?.b1t) + parseFloatAt(response?.b1h) + parseFloatAt(response?.b1d)).toFixed(1)}<br/>
                                            <TextfieldInput name={"Neutral"} tag={`${eq_ID}_neutral`} width={60} labelWidth={70} ph={0}/>
                                        </>

                                }
                                <Status type={"error"} text={valueValidationErrors}/>
                            </>
                        </div>
                    }
                    {
                        exam.type.value === ExamTypeMap.synchronous && writeable() && <><MaxBtn onClick={() => saveAndRedir(examquestion_index)}>
                            speichern
                        </MaxBtn>&nbsp;</>
                    }

                    {
                        examstart.parent_ID !== null && !others.includes(examstart.person_ID) && exam.type.value === ExamTypeMap.synchronous ?
                            // admin is viewing others synchronous exam
                            <>

                                {
                                    exammap[examquestion_index - 1] && <><MaxBtn onClick={() => saveAndRedir(examquestion_index - 1, false)}>
                                        zurück
                                    </MaxBtn>&nbsp;</>
                                }
                                {
                                    exammap[examquestion_index + 1] && <><MaxBtn onClick={() => saveAndRedir(examquestion_index + 1, false)}>
                                        nächste
                                    </MaxBtn>&nbsp;</>
                                }
                            </> :
                            <>
                                {
                                    exammap[examquestion_index - 1] && exam.type.value === ExamTypeMap.selfservice && <><MaxBtn onClick={() => saveAndRedir(examquestion_index - 1)}>
                                        {writeable() && "speichern & "}zurück
                                    </MaxBtn>&nbsp;</>
                                }
                                {
                                    exammap[examquestion_index + 1] && <><MaxBtn onClick={() => saveAndRedir(examquestion_index + 1)}>
                                        {writeable() && "speichern & "}nächste
                                    </MaxBtn>&nbsp;</>
                                }
                            </>
                    }

                    {
                        examquestion_index + 1 === Object.keys(exammap).length ? <>
                            {
                                writeable() && exam.type.value === ExamTypeMap.selfservice && <>
                                    <MaxBtn onClick={() => saveAndRedir(examquestion_index)}>
                                        speichern
                                    </MaxBtn>&nbsp;
                                    <p>
                                        Dies ist die letzte Frage in der Prüfung. Du kannst entweder noch einmal zurückgehen und die Fragen anpassen oder die Prüfung beenden und das Ergebnis ansehen.
                                    </p>
                                </>
                            }


                            {
                                examstart.parent_ID === null && exam.type.value === ExamTypeMap.synchronous ?
                                    <MaxBtn onClick={() => window.confirm("Möchtest du die Prüfung wirklich beenden und zum Ergebnis aller springen?") && saveAndRedir("resultatAlle")}>
                                        Resultat aller
                                    </MaxBtn> :
                                    <MaxBtn onClick={() => saveAndRedir(examstart.parent_ID === null && exam.type.value === ExamTypeMap.synchronous ? "resultatAlle" : "resultat")}>
                                        {writeable() && "speichern & "}Ergebnis ansehen
                                    </MaxBtn>


                            }

                        </> : <>
                            {
                                examstart.parent_ID !== null && !others.includes(examstart.person_ID) && exam.type.value === ExamTypeMap.synchronous &&
                                <>
                                    <MaxBtn onClick={() => saveAndRedir("resultat", false, examstart.ID)}>
                                        Resultat
                                    </MaxBtn> &nbsp;
                                    <MaxBtn onClick={() => window.confirm("Möchtest du die Prüfung wirklich beenden und zum Ergebnis aller springen?") && saveAndRedir("resultatAlle", false, examstart.parent_ID)}>
                                        Resultat aller
                                    </MaxBtn>
                                </>
                            }
                        </>
                    }
                    {
                        exam.type.value === ExamTypeMap.selfservice && writeable() && <ShowClock start={new Date(new Date(examstart.timeCreated).getTime() + 60 * 40 * 1000)} inverse/>
                    }
                </>
            }
            &nbsp;
            {
                user?.role >= 38 && examquestion?.examquestion?.ID && (examquestion?.examstart?.matrix || examquestion?.examstart?.passed !== null) && <>
                    <p>Antritt von {person.fullname}
                    </p>
                    <UserHistory userID={examquestion?.examquestion?.ID} key={examquestion?.examquestion?.ID} personType={"examquestion"}/>
                    <MyModal trigger={<MaxBtn>Frage öffnen</MaxBtn>}>
                        {
                            close => <QuestionEditWrapper question_ID={examquestion?.question.ID} exam={exam} close={() => {
                                close()
                            }}/>
                        }
                    </MyModal>
                </>
            }
            <Loader loading={loading}/>
        </MyFormContextWrapper>

    </Container>
}

Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}


function DurationFormatter({children}) {
    const duration = children
    return <>
        {Math.floor((duration % 3600) / 60).pad(2) + ":"}
        {Math.floor(duration % 60).pad(2) + ""}
    </>
}

function ShowClock({start, inverse}) {
    const [sinceStart, setSinceStart] = useState(0)
    useEffect(() => {
        const tmp = () => setSinceStart(Math.max(0, inverse ? start - new Date() : new Date() - start))
        tmp()
        const timer = setInterval(tmp, 1000)
        return () => clearInterval(timer)
    }, [start]);


    return <>
        <h3>
            {inverse && "Verbleibende"} Bearbeitungs-Zeit: <DurationFormatter>{sinceStart / 1000}</DurationFormatter>
        </h3>
    </>
}