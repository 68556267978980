import React from "react";
import {Container, Loader, MaxBtn} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {CheckboxInput, DateInput, EnumInput, InputContainer, LightContainer, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import Consts from "../core/consts";
import {FaCheckCircle} from "react-icons/all";
import {Club} from "../core/custom";
import {PersonType, SectorType, SectorTypeMap} from "../core/enums";
import ChangeAddressExt from "./addressExt";
import VereinID from "./vereinid";

const fieldsRequired = ["forename", "surname", "sex", "dateOfBirth"];

export default class UserEdit extends React.Component {

    setState = this.setState.bind(this);
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    constructor(props) {
        super(props);
        const registerMode = this.props.location?.pathname === "/registrieren"
        this.state = {
            user: registerMode ?
                {
                    country: "AT"
                } :
                null,
            loading: !registerMode,
            registerMode,
        };

    }

    componentDidMount = () => {
        if (!this.state.registerMode) {
            this.apiGet("/user" + (this.props.user_ID ? "/" + this.props.user_ID : ""), ({user}) => {
                this.setState({user}, () => {
                    this.checkPostalCode()
                })
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state, this.props, this.state[this.props.id])
        if (this.state.registerMode) {
            if (this.state.user.dataprotection !== 1) {
                return this.setState({error: "Bitte das Hakerl bei Einwilligung Datenschutz setzen!"})
            }
        }
        this.setState({loading: true});
        this.apiPost(this.state.registerMode ? "/user/register" : "/user", this.state.user, resp => {
            this.setState({
                success: this.state.registerMode ? "Die Registrierung ist erfolgreich und ein E-Mail mit weiteren Anweisungen wurde versandt." : "Die Daten wurden erfolgreich gespeichert."
            })
            this.props.reload && this.props.reload()
        })
    };
    checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = this.state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            this.apiPost("/user/address/email", {email: val}, resp => {
                if (resp.email === (this.state[s[0]] || {}).email) {
                    this.setState({emailValid: resp.valid})
                }
            })
        }
    };
    checkPostalCode = (e) => {
        if (this.state.user.country !== "AUT" || !this.state.user.postalCode) {
            return
        }
        this.apiGet("/postalCode/" + this.state.user.postalCode, resp => {
            this.setState({postalCode: resp.postalCode})
        })
    };

    render() {
        const {user, error, success, loading, postalCode, registerMode} = this.state;
        return <Container name={registerMode ? "Für die Turnsport Austria Meldeplattform registrieren" : "Benutzerdaten bearbeiten"}>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <Loader loading={loading}/>
            {
                (user || registerMode) && (!registerMode || !success) && <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                    {
                        ["sex", "forename", "surname", "dateOfBirth", "email", "phone", "licenceNumber"]
                            .map((f, i) => {
                                switch (f) {
                                    case "country":
                                        return <InputContainer>
                                            <SelectfieldInput name={"Land"} tag={"user_" + f} selectives={[["AUT", "Österreich"], ["GER", "Deutschland"]]}/>
                                            {i % 2 === 0 && <br/>}
                                        </InputContainer>
                                    case "type":
                                        return [<InputContainer>
                                            <EnumInput name={"Art"} tag={"user_" + f} type="reactselect" selectives={PersonType}/>
                                        </InputContainer>, <br/>]
                                    case "sex":
                                        return [<InputContainer>
                                            <SelectfieldInput name={"Geschlecht*"} tag={"user_" + f} demandSelect selectives={[["0", "Weiblich"], ["1", "Männlich"]]}/>
                                        </InputContainer>, <br/>]
                                    case "email":
                                        return <InputContainer>
                                            <TextfieldInput
                                                onBlur={this.checkPhone}
                                                inputBorderColor={this.state.emailValid === false ? "red" : null}
                                                name={<>{Consts.translate(f)}* {this.state.emailValid === true && <FaCheckCircle/>}{this.state.emailValid === false && "(falsch)"}</>}
                                                tag={"user_" + f}
                                            />
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    case "phone":
                                        return <InputContainer>
                                            <TextfieldInput
                                                ph={Consts.placeHolders[f]}
                                                onBlur={this.checkPhone}
                                                name={Consts.translate(f)}
                                                tag={"user_" + f}
                                            />
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    case "dateOfBirth":
                                        return <InputContainer>
                                            <DateInput name={Consts.translate(f) + "*"} tag={"user_" + f}/>
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>

                                    case "postalCode":
                                        return <>
                                            <TextfieldInput name={Consts.fieldTranslation[f] || f} tag={"user_" + f}/>
                                            {i % 2 === 1 && <br/>}
                                        </>
                                    case "licenceNumber":
                                        if (!this.state.user?.licenceNumber) return null

                                        return <InputContainer>
                                            <TextfieldInput readOnly ph={Consts.placeHolders[f]} name={(Consts.fieldTranslation[f] || f) + (fieldsRequired.includes(f) ? "*" : "")} tag={"user_" + f}/>
                                            {i % 2 === 1 && <br/>}
                                        </InputContainer>
                                    default:
                                        return <InputContainer>
                                            <TextfieldInput ph={Consts.placeHolders[f]} name={(Consts.fieldTranslation[f] || f) + (fieldsRequired.includes(f) ? "*" : "")} tag={"user_" + f}/>
                                            {i % 2 === 1 && <br/>}
                                        </InputContainer>
                                }
                            })
                    }
                    {
                        user.role > 80 && <InputContainer><SelectfieldInput name={"Berechtigung"} tag={"user_permission"} selectives={Object.entries(Consts.permissionMap)}/></InputContainer>
                    }

                    <br/>
                    <EnumInput name={"Sparte"} tag={"user_sectors"} multiple type="reactselect" selectives={SectorType.filter(a => [SectorTypeMap.crossSector, SectorTypeMap.gt, SectorTypeMap.gti].includes(a.value) === false)}/>

                    <TagsInput multiple name={"Verein*"} entity={"club"} ID={0} tag={"user_clubs"}/>
                    <ChangeAddressExt required child={user} changeAddress={a => {
                        this.setState(old => ({user: {...old.user, ...a}}))
                    }}/>
                    <br/>
                    {
                        registerMode && [<div style={{clear: "both"}}>
                            <h2>DSGVO-Bestimmungen</h2>
                            <p>Ich bestätige hiermit rechtsverbindlich, dass ich für alle von mir angemeldeten Personen verbindlich berechtigt wurde, diese Anmeldung in ihrem Auftrag und Namen durchzuführen. Sollte ich nur mich selbst anmelden, gelten alle nachfolgenden Bestimmungen für mich selbst.</p>
                            <p>Ich bestätige hiermit, dass ich alle von mir angemeldeten Personen rechtsverbindlich zur Einhaltung aller Bestimmungen der  Turnsport Austria verpflichtet habe, die im Zusammenhang mit der Veranstaltung stehen, für die diese Anmeldung erfolgt. </p>
                            <p>Turnsport Austria wird von mir und von den von mir angemeldeten Personen im Zusammenhang mit der Veranstaltung, für die diese Anmeldung erfolgt, schad- und klaglos gehalten. </p>
                            <p>Ich bestätige hiermit rechtsverbindlich, dass ich von allen von mir angemeldeten Personen die rechtsverbindliche Bestätigung eingeholt habe, dass ihre bei der Anmeldung anzugebenden Daten zur Gänze und/oder in modifizierter Form von  Turnsport Austria ohne Befristung gespeichert,
                                verarbeitet, an Turnsport-Austria-Auftragsverarbeiter (z.B. für die EDV-Wettkampfauswertung) weitergegeben und in beliebiger Form veröffentlicht werden dürfen. </p>
                            <p>Ein Widerruf und/oder eine Nichtdurchführung einer oder aller der o.a. Bestätigungen ist jederzeit vor der Veranstaltung für/von alle/n oder einzelne/n von mir angemeldeten Personen möglich. Widerrufe sind per Email an office@turnsport.at zu richten. Ich nehme allerdings zur
                                Kenntnis, dass ein solcher Widerruf und/oder eine Nichtdurchführung einer Bestätigung bedeutet, dass eine Teilnahme an der Veranstaltung nicht möglich sein kann. Durch die Anmeldung fällig gewordenes Nenngeld verbleibt im Falle eines Widerrufs und somit einer Nichtteilnahme
                                fällig bzw. wird nicht rückerstattet.</p>
                            <p>Ich bestätige hiermit rechtsverbindlich, von allen von mir angemeldeten Personen das uneingeschränkte und unwiderrufliche Einverständnis eingeholt zu haben, bei der Veranstaltung gefilmt, fotografiert und/oder tonaufgezeichnet zu werden – sowie, dass sich daraus für mich
                                und/oder die angemeldeten Personen keine Rechte (z.B. Entgelt) ableiten.</p>
                            <p>Ich bestätige weiters, das uneingeschränkte und unwiderrufliche Einverständnis aller von mir angemeldeten Personen eingeholt zu haben, dass diese o.a. Filme, Fotos und/oder Tonaufnahmen zur für die Angemeldeten entgeltfreien Live- oder beliebig zeitversetzten Veröffentlichung
                                mit oder ohne Namensnennung durch  Turnsport Austria, dessen Landesverbände und Vereine sowie kooperierende Medien und Partner unbefristet verwendet und ohne zeitliche Beschränkung gespeichert sowie mit oder ohne Namensnennung veröffentlicht werden können. </p>
                            <p>Ich bestätige insbesondere, dass  Turnsport Austria berechtigt ist, bei der Veranstaltung erstellte Fotos, Videos und Tonaufzeichnungen der von mir angemeldeten Personen im Sinne seines gemeinnützigen Zweckes zur Förderung des Turnsports kommerziell zu verwerten und/oder andere
                                Personen/Organisationen/Unternehmen damit zu beauftragen. </p>

                            <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                                <CheckboxInput name={"akzeptiere"} tag={"user_dataprotection"} noLabel/>
                            </div>
                            <div>
                                <br/>
                                Hiermit bestätige ich, dass ich alle 8 oben angeführten Absätze zur Kenntnis genommen habe.

                            </div>
                        </div>,
                        ]
                    }
                    <br/>
                    <MaxBtn>{registerMode ? "Registrieren" : "Speichern"}</MaxBtn>
                </FormContextWrapper>
            }
            {
                this.props.profil && <VereinID/>
            }
        </Container>
    }
}
